import React, {useState} from "react";
import {Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, Typography} from "@material-ui/core";
import {useIntl} from "react-intl";
import LabelIcon from '@material-ui/icons/Label';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import globalState from "../globalState";
import { useStoreState } from "pullstate";
import { useMfwThemeProvider } from "myfitworld-utils";
import messages from "../../src/components/messages";
import globalMessages from "../messages";
import useBillingDetails from "../hooks/useBillingDetails";
import {StringStyleParser} from "../utils/StringStyleParser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelIcon1: {
      position: 'absolute',
      bottom: 5,
      left: 10,
      transform: 'rotate(180deg)'
    },
    labelIcon2: {
      position: 'absolute',
      bottom: 5,
      left: 5,
      transform: 'rotate(180deg)'
    },
    rowStyle: {
      display: 'flex',
      flexDirection: 'row'
    }
  }),
);

export default () => {
  const {formatMessage} = useIntl();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const discountNumber = currentOrganization?.stripe?.discountName?.slice(0, 2);
  const theme = useMfwThemeProvider();
  const classes = useStyles();
  const customStyle: React.CSSProperties = { fontWeight: 'bold' };

  const { billingDetails } = useBillingDetails(currentOrganization?.id);
  const clientQuantity = billingDetails?.stripe.quantity;

  const [openDiscountPopup, setOpenDiscountPopup] = useState(false);

  const openPopup = (e: any) => {
    e.stopPropagation();
    setOpenDiscountPopup(true);
  }
  
  const onCancel = (e: any) => {
    e.stopPropagation();
    setOpenDiscountPopup(false);
  }

  const categorizeByQuantity = (clientQuantity: number) => {
    const quantity = clientQuantity || 0;

    switch (true) {
      case (quantity >= 0 && quantity <= 2):
        return `0-2 ${formatMessage(messages.rebrand_clients)}`;
      case (quantity >= 3 && quantity <= 14):
        return `3-14 ${formatMessage(messages.rebrand_clients)}`;
      case (quantity >= 15 && quantity <= 29):
        return `15-29 ${formatMessage(messages.rebrand_clients)}`;
      case (quantity >= 30 && quantity <= 49):
        return `30-49 ${formatMessage(messages.rebrand_clients)}`;
      case (quantity >= 50 && quantity <= 99):
        return `50-99 ${formatMessage(messages.rebrand_clients)}`;
      case (quantity >= 100):
        return `100+ ${formatMessage(messages.rebrand_clients)}`;
      default:
        return;
    }
  }

  return (
    <Box>
      <Box style={{ position: 'relative' }} onClick={openPopup}>
        <AccountBalanceWalletIcon style={{ display: 'block', color: theme.mfwMuiWebTheme.palette.primary.main }}/>
        <LabelIcon className={classes.labelIcon1} color='primary' fontSize='large'/>
        <LabelIcon className={classes.labelIcon2} color='primary' fontSize='large'/>
        
        <Typography style={{color: 'black', fontSize: '0.8rem', position: 'absolute', bottom: 13, left: 15}}>
          {discountNumber}%
        </Typography>
      </Box>

      {openDiscountPopup &&
        <Dialog open={openDiscountPopup} onClose={onCancel}>
          <DialogTitle>{formatMessage(messages.rebrand_discount_badge_title)}</DialogTitle>
          
          {/*NO Rebrand, MORE/LESS than 3 clients*/}
          {!currentOrganization?.haveRebrand &&
            <DialogContent>
              {!currentOrganization?.haveRebrand && (clientQuantity && clientQuantity > 2) ?
                <Box>
                  <Typography>{formatMessage(messages.rebrand_current_billing_discount)}</Typography>
                  <Box className={classes.rowStyle}>
                    <Typography>{currentOrganization?.stripe?.discountName || 'No Discount'}</Typography>
                    &nbsp;
                    <Typography>({clientQuantity && categorizeByQuantity(clientQuantity)})</Typography>
                  </Box>
                  <br/>
                </Box>
                :
                <Box>
                  <Typography>
                    <StringStyleParser text={formatMessage(messages.rebrand_discount_badge_text1)} customStyle={customStyle} />
                  </Typography>
                  <br/>
                </Box>
              }
              {!currentOrganization?.haveRebrand && (clientQuantity && clientQuantity > 2) &&
                <Box>
                  <Typography>{formatMessage(messages.rebrand_pricing_details)}</Typography>
                  <br/>
                  <Typography>
                    <StringStyleParser text={formatMessage(messages.rebrand_pricing_explanation)} customStyle={customStyle} />
                  </Typography>
                  <br/>
                </Box>
              }
              <Box>
                <Typography>
                  <StringStyleParser text={formatMessage(messages.rebrand_standard_monthly_price)} customStyle={customStyle} />
                </Typography>
                <br/>
                <Box ml={2}>
                  <ul>
                    <li>{formatMessage(messages.standard_discount_badge_0_2)}</li>
                    <li>{formatMessage(messages.standard_discount_badge_3_14)}</li>
                    <li>{formatMessage(messages.standard_discount_badge_15_29)}</li>
                    <li>{formatMessage(messages.standard_discount_badge_30_49)}</li>
                    <li>{formatMessage(messages.standard_discount_badge_50_99)}</li>
                    <li>{formatMessage(messages.standard_discount_badge_100)}</li>
                  </ul>
                </Box>
                <br/>
                <Typography>
                  <StringStyleParser text={formatMessage(messages.rebrand_stripe_payment)} customStyle={customStyle} />
                </Typography>
                <br/>
                <Typography>
                  <StringStyleParser text={formatMessage(messages.rebrand_rebrand_monthly_price)} customStyle={customStyle} />
                </Typography>
                <br/>
                <Box ml={2}>
                  <ul>
                    <li>
                      <StringStyleParser text={formatMessage(messages.rebrand_discount_badge_0_2)} customStyle={customStyle} />
                    </li>
                    <li>{formatMessage(messages.rebrand_discount_badge_3_14)}</li>
                    <li>{formatMessage(messages.rebrand_discount_badge_15_29)}</li>
                    <li>{formatMessage(messages.rebrand_discount_badge_30_49)}</li>
                    <li>{formatMessage(messages.rebrand_discount_badge_50_99)}</li>
                    <li>{formatMessage(messages.rebrand_discount_badge_100)}</li>
                  </ul>
                </Box>
              </Box>
              <Box mt={2}>
                <Typography>
                  <StringStyleParser text={formatMessage(messages.rebrand_discount_badge_note)} customStyle={customStyle} />
                </Typography>
              </Box>
            </DialogContent>
          }

          {/*HAS Rebranded, MORE/LESS than 3 clients*/}
          {currentOrganization?.haveRebrand &&
            <DialogContent>
              <Box>
                <Typography>{formatMessage(messages.rebrand_current_billing_discount)}</Typography>
                <Box className={classes.rowStyle}>
                  <Typography>{currentOrganization?.stripe?.discountName || formatMessage(messages.rebrand_no_discount)}</Typography>
                  &nbsp;
                  <Typography>({clientQuantity && categorizeByQuantity(clientQuantity)})</Typography>
                </Box>
                <br/>
              </Box>
              <Typography>
                <StringStyleParser text={formatMessage(messages.rebrand_discount_exclusive_pricing)} customStyle={customStyle} />  
              </Typography>
              <br/>
              <Box ml={2}>
                <ul>
                  <li>
                    <StringStyleParser text={formatMessage(messages.rebrand_discount_badge_0_2)} customStyle={customStyle}/>
                  </li>
                  <li>{formatMessage(messages.rebrand_discount_badge_3_14)}</li>
                  <li>{formatMessage(messages.rebrand_discount_badge_15_29)}</li>
                  <li>{formatMessage(messages.rebrand_discount_badge_30_49)}</li>
                  <li>{formatMessage(messages.rebrand_discount_badge_50_99)}</li>
                  <li>{formatMessage(messages.rebrand_discount_badge_100)}</li>
                </ul>
              </Box>
              <br/>
              <Typography>{formatMessage(messages.rebrand_please_note)}</Typography>
              <Typography>{formatMessage(messages.rebrand_prices_applied)}</Typography>
              <br/>
              <Typography>
                <StringStyleParser text={formatMessage(messages.rebrand_if_when_cancel_rebrand)} customStyle={customStyle} />
              </Typography>
              <br/>
              <Box ml={2}>
                <ul>
                  <li>{formatMessage(messages.standard_discount_badge_0_2)}</li>
                  <li>{formatMessage(messages.standard_discount_badge_3_14)}</li>
                  <li>{formatMessage(messages.standard_discount_badge_15_29)}</li>
                  <li>{formatMessage(messages.standard_discount_badge_30_49)}</li>
                  <li>{formatMessage(messages.standard_discount_badge_50_99)}</li>
                  <li>{formatMessage(messages.standard_discount_badge_100)}</li>
                </ul>
              </Box>
              <Box mt={2}>
                <Typography>
                  <StringStyleParser text={formatMessage(messages.rebrand_purchase_lifetime_rebrand)} customStyle={customStyle} />
                  <StringStyleParser text={formatMessage(messages.rebrand_manage_rebrand_subscription)} customStyle={customStyle} />
                </Typography>
              </Box>
            </DialogContent>
          }
          <DialogActions>
            <Button size="large" color="primary" onClick={onCancel}>{formatMessage(globalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      }
    </Box>
  );
}
