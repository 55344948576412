import React, { useState, useContext, useRef, ChangeEvent, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { firestore } from "../../firebase";
import globalState, { GlobalState } from "../../globalState";
import UserContext from "../../contexts/UserContext";
import { Alert } from "@material-ui/lab";
import { navigate } from "@reach/router";
import { Role } from "myfitworld-model";
import firebase from "firebase";
import { useMfwThemeProvider } from "myfitworld-utils";
import { buildUrl, configMainRoutesConsts } from "../../config/route";
import { useIntl } from "react-intl";
import scheduleMessages from "./messages";
import globalMessages from "../../messages";
import { callDeleteAccount } from "../../api/customAuthApi";
import LoadingSpinner from "../../components/LoadingSpinner";

const NON_CLIENT_ROLES = new Set([Role.SuperAdmin, Role.Admin, Role.Trainer, Role.AssistantTrainer]);

const VerificationDialog = () => {
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const theme = useMfwThemeProvider();
  const [wrongCodeMessage, setWrongCodeMessage] = useState(false);
  const [firstDigit, setFirstDigit] = useState<string>();
  const [secondDigit, setSecondDigit] = useState<string>();
  const [thirdDigit, setThirdDigit] = useState<string>();
  const [fourthDigit, setFourthDigit] = useState<string>();
  const [repeatRegistration, setRepeatRegistration] = useState(false);
  const [enteredRandomNumber, setEnteredRandomNumber] = useState("");
  const [wrongRandomNumberMessage, setWrongRandomNumberMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const randomNumber = useMemo(() => Math.round(Math.random() * 100000), [repeatRegistration]);
  const isClient = useMemo(() => {
    if (!user?.organizations) return true;
    return !user.organizations.some((org) => NON_CLIENT_ROLES.has(org.role));
  }, [user]);
  const isOpened = ( user && !user?.isVerified ) || false;

  const onSubmit = (e: any) => {
    e.preventDefault();
    setWrongCodeMessage(false);
    if (firstDigit && secondDigit && thirdDigit && fourthDigit) {
      const verificationCode =
        firstDigit.toString() + secondDigit.toString() + thirdDigit.toString() + fourthDigit.toString();
      user?.verificationCode && user.verificationCode === verificationCode
        ? successVerification()
        : unsuccessVerification();
    }
  };

  const successVerification = () => {
    firestore.collection("users").doc(user?.id).update({ isVerified: true });
    firestore
      .collection("invitations")
      .where("email", "==", user?.email)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          doc.ref
            .update({
              acceptedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            })
            .catch((err) => console.error(`Error acceptedOn in invitation`, err));
        });
      });
    //obratiti paznju kada budu mogle pozivnice sa vise mesta!!!!!!
    globalState.update((state: GlobalState) => {
      state.toastQueue.push({
        message: formatMessage(scheduleMessages.account_successfully_confirmed),
        severity: "success",
      });
    });
  };

  const unsuccessVerification = () => {
    setWrongCodeMessage(true);
  };

  const isCorrectRandomNumber = () => {
    if (randomNumber === +enteredRandomNumber) {
      return true;
    } else {
      setWrongRandomNumberMessage(formatMessage(scheduleMessages.wrong_number));
      return false;
    }
  };

  const registerAgain = async () => {
    const language = user?.language;
    if (isCorrectRandomNumber()) {
      setLoader(true);
      callDeleteAccount("repeat_registration")
        .then((e) => {
          setLoader(false);
          navigate(
            buildUrl(
              isClient ? configMainRoutesConsts.createUserAccount : configMainRoutesConsts.trainerOrganizationLoginPage,
              [language || "en"]
            )
          );
        })
        .catch((e) => {
          console.log("error : ", e);
        });
    }
  };

  const handleInputChange = (index: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value;
    if (input.length === 1 && index < inputRefs.length - 1 && inputRefs[index + 1].current) {
      inputRefs[index + 1].current?.focus();
    }
  };

  return (
    <Dialog onClose={() => {}} open={isOpened} maxWidth="sm" fullWidth disableBackdropClick>
      {!loader && (
        <DialogTitle id="simple-dialog-title">
          <Typography variant="h3">
            {repeatRegistration
              ? formatMessage(scheduleMessages.repeat_registration)
              : formatMessage(scheduleMessages.account_confirmation)}
          </Typography>
        </DialogTitle>
      )}
      {repeatRegistration ? (
        !loader ? (
          <DialogContent style={{ padding: 10 }}>
            <DialogContentText style={{ textAlign: "center" }}>
              {formatMessage(scheduleMessages.repeat_registration_text)}
              <br />
              {formatMessage(scheduleMessages.random_number_text, { randomNumber: randomNumber })}
            </DialogContentText>
            <Box style={{ textAlign: "center" }}>
              <Typography color="error">{wrongRandomNumberMessage}</Typography>
              <TextField
                value={enteredRandomNumber}
                onChange={(event) => {
                  setEnteredRandomNumber(event.target.value);
                }}
              />
              <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 20 }}>
                <Button
                  onClick={() => setRepeatRegistration(false)}
                  color="secondary"
                  variant="outlined"
                  style={{ marginRight: 30 }}
                >
                  {formatMessage(globalMessages.cancel)}
                </Button>
                <Button onClick={registerAgain} color="primary" variant="outlined" autoFocus>
                  {formatMessage(globalMessages.confirm)}
                </Button>
              </Box>
            </Box>
          </DialogContent>
        ) : (
          <Box style={{ padding: 30 }}>
            <LoadingSpinner />
            <Typography variant="h4" color="primary" style={{ textAlign: "center", marginTop: 5 }}>
              {formatMessage(scheduleMessages.deleting_account)}
            </Typography>
          </Box>
        )
      ) : (
        <DialogContentText style={{ padding: 10 }}>
          {wrongCodeMessage && <Alert severity="error">{formatMessage(scheduleMessages.wrong_code_try_again)}</Alert>}
          <Typography variant="h5" style={{ textAlign: "center", marginTop: 10 }}>
            <span style={{ color: theme.mfwMuiWebTheme.palette.primary.main }}>{theme.theme.whitelabel.name} </span>
            {formatMessage(scheduleMessages.account_successfully_confirmed)}&nbsp;
            {formatMessage(
              scheduleMessages.it_is_necessary_to_enter_the_verification_code_that_was_sent_to_the_email
            )}{" "}
            <span style={{ color: theme.mfwMuiWebTheme.palette.primary.main }}>{user?.email}</span>{" "}
            {formatMessage(scheduleMessages.to_confirm_the_registration)}
          </Typography>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <form onSubmit={onSubmit}>
              <Box flexDirection="row" display="flex" justifyContent="center" marginTop={2}>
                <TextField
                  inputRef={inputRefs[0]}
                  variant="outlined"
                  name="firstDigit"
                  style={{ width: 50, marginRight: 5 }}
                  autoComplete="off"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) => {
                    handleInputChange(0, e);
                    setFirstDigit(e.target.value);
                  }}
                  autoFocus
                />
                <TextField
                  inputRef={inputRefs[1]}
                  variant="outlined"
                  name="secondDigit"
                  style={{ width: 50, marginRight: 5 }}
                  autoComplete="off"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) => {
                    handleInputChange(1, e);
                    setSecondDigit(e.target.value);
                  }}
                />
                <TextField
                  inputRef={inputRefs[2]}
                  variant="outlined"
                  name="thirdDigit"
                  style={{ width: 50, marginRight: 5 }}
                  autoComplete="off"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) => {
                    handleInputChange(2, e);
                    setThirdDigit(e.target.value);
                  }}
                />
                <TextField
                  inputRef={inputRefs[3]}
                  variant="outlined"
                  name="fourthDigit"
                  style={{ width: 50, marginRight: 5 }}
                  autoComplete="off"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) => {
                    handleInputChange(3, e);
                    setFourthDigit(e.target.value);
                  }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={onSubmit}
                style={{ marginTop: 15, marginLeft: 60 }}
              >
                {formatMessage(scheduleMessages.confirm)}
              </Button>
            </form>
          </Box>
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Typography style={{ textAlign: "center" }}>
              {formatMessage(scheduleMessages.if_the_displayed_email_is_not_correct_you_can)}{" "}
              <a
                onClick={() => setRepeatRegistration(true)}
                style={{
                  color: theme.mfwMuiWebTheme.palette.primary.main,
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                {formatMessage(scheduleMessages.repeat_the_registration_here)}
              </a>
            </Typography>
          </Box>
        </DialogContentText>
      )}
    </Dialog>
  );
};

export default VerificationDialog;
