import React, {useState, Fragment, useEffect} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import Image from "material-ui-image";
import a2hsIOSImage from "../../assets/images/a2hsIOS.png";
import {useAddToHomeScreenPrompt} from "../../utils/useAddToHomeScreenPrompt";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import { useMfwThemeProvider } from "myfitworld-utils";
import {getMobileDeviceType, BrowserType} from "../../utils/MobileDeviceType";
import { useIntl } from "react-intl";
import dialogMessages from "./messages";
import globalMessages from "../../messages";
import {useStoreState} from "pullstate";
import globalState from "../../globalState";

import myFitWorldLogo from "../../assets/new_logo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeBtn: {
      marginTop: 10,
      marginLeft: 20
    },
    iconBtn: {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0
    },
    logoLink: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      display: "flex",
      width: 35,
      padding: theme.spacing(1),
    }
  }),
);

export const InstallHomeScreen = () => {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const theme = useMfwThemeProvider();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  
  return (
    <>
      <Box className={classes.logoLink}>
        {currentOrganization?.haveRebrand ?
          <img src={myFitWorldLogo} className={classes.logo} alt="Logo"/>
          :
          <img src={theme.theme.theme.logo} className={classes.logo} alt="Logo"/>
        }
      </Box>
      <Typography variant='h3'>{formatMessage(dialogMessages.symbol_plus)}</Typography> &nbsp;
      <Typography variant='h6'> {formatMessage(dialogMessages.button_install_icon)}</Typography>
    </>
  )
}

const A2hscDialog = () => {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const theme = useMfwThemeProvider();
  const [a2hs, setA2hs] = useState(false);
  const [prompt, promptToInstall, isIos, browserType] = useAddToHomeScreenPrompt();
  const [safariModal, openSafariModal] = useState(false);

  let browserSafari: BrowserType = 'Safari';
  const userAgent = navigator.userAgent;
  const deviceType = getMobileDeviceType(userAgent);

  const onInstall = () => {
    switch (deviceType) {
      case 'android':
        if (prompt) {
          promptToInstall();
        }
        break;
      case 'ios':
        openSafariModal(true);
        break;
      default:
       return;
    }
  }
  
  useEffect(() => {
    if (prompt) {
      setA2hs(true);
    }
  }, [prompt]);

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" position="absolute" right={0}>
        {((a2hs) || (!a2hs && browserType === browserSafari)) &&
          <Box display="flex" flexDirection="column">
            <Button
              variant="text"
              color="secondary"
              onClick={onInstall}
            >
              <InstallHomeScreen />  
            </Button>
          </Box>
        }
      </Box>

      {safariModal &&
        <Dialog open={safariModal}>
          <DialogTitle>
            <Box pt={1}>
              <Typography variant='h4' component='p'>
              {formatMessage(dialogMessages.add_wl_to_homescreen, {name: theme.theme.whitelabel.name})}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box mt={2} mb={2} display="flex" flexDirection="column" alignItems="center">
              <Box style={{height: 250, width: '70%'}}>
                <Typography variant='h4' component='p'>
                  {formatMessage(dialogMessages.install_icon_instructions)}
                </Typography>
                <Image
                  src={a2hsIOSImage}
                  disableSpinner
                  color={theme.mfwMuiWebTheme.palette.background.default}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.closeBtn}
              variant="contained"
              color="secondary"
              onClick={() => {
                openSafariModal(false);
              }}
            >
              {formatMessage(globalMessages.close)}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </Fragment>
  )
};

export default A2hscDialog;
